import styled, { css } from "styled-components";

interface StyledButtonProps {
  variant?: "primary" | "secondary" | "danger" | "outline" | "text" | "icon";
  size?: "small" | "medium" | "large";
  rounded?: boolean;
  disabled?: boolean;
  loading?: boolean;
  flex?: boolean;
  isIcon?: boolean; // Specific to icon buttons
}

const variantStyles = {
  primary: css`
    background-color: ${(props) => props.theme.colors.primary};
    color: ${(props) => props.theme.colors.white};
    border: none;

    &:hover {
      background-color: ${(props) => props.theme.colors.secondary};
    }
  `,
  secondary: css`
    background-color: ${(props) => props.theme.colors.secondary};
    color: ${(props) => props.theme.colors.white};
    border: none;

    &:hover {
      background-color: ${(props) => props.theme.colors.primary};
    }
  `,
  danger: css`
    background-color: ${(props) => props.theme.colors.danger};
    color: ${(props) => props.theme.colors.white};
    border: none;

    &:hover {
      background-color: #c0392b;
    }
  `,
  outline: css`
    background-color: transparent;
    color: ${(props) => props.theme.colors.primary};
    border: 2px solid ${(props) => props.theme.colors.primary};

    &:hover {
      background-color: ${(props) => props.theme.colors.primary};
      color: ${(props) => props.theme.colors.white};
    }
  `,
  text: css`
    background-color: transparent;
    color: ${(props) => props.theme.colors.primary};
    border: none;

    &:hover {
      text-decoration: underline;
    }
  `,
  icon: css`
    background-color: transparent;
    color: ${(props) => props.theme.colors.primary};
    border: none;
    padding: 0; /* Remove padding for icon-only buttons */
    width: 36px;
    height: 36px; /* Consistent sizing for icons */
    display: inline-flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;

    &:hover {
      background-color: ${(props) => props.theme.colors.secondary};
      color: ${(props) => props.theme.colors.white};
    }
  `,
};

const sizeStyles = {
  small: css`
    font-size: ${(props) => props.theme.typography.fontSize.small};
    padding: ${(props) => props.theme.spacing.small};
  `,
  medium: css`
    font-size: ${(props) => props.theme.typography.fontSize.medium};
    padding: ${(props) => props.theme.spacing.medium};
  `,
  large: css`
    font-size: ${(props) => props.theme.typography.fontSize.large};
    padding: ${(props) => props.theme.spacing.large};
  `,
};

export const StyledButton = styled.button.withConfig({
  shouldForwardProp: (prop) =>
    ![
      "variant",
      "size",
      "rounded",
      "disabled",
      "loading",
      "flex",
      "isIcon",
    ].includes(prop),
})<StyledButtonProps>`
  display: ${(props) => (props.flex ? "flex" : "inline-flex")};
  justify-content: center;
  align-items: center;
  border-radius: ${(props) =>
    props.rounded ? "50px" : props.theme.borderRadius.medium};
  font-weight: ${(props) => props.theme.typography.fontWeight.bold};
  cursor: pointer;
  transition: all 0.3s ease;
  ${(props) => props.variant && variantStyles[props.variant]};
  ${(props) => props.size && sizeStyles[props.size]};

  ${(props) =>
    props.flex &&
    css`
      flex: 1;
    `}

  /* Icon Button Specific Styling */
  ${(props) =>
    props.variant === "icon" &&
    css`
      width: ${(props.size === "small" && "30px") ||
      (props.size === "medium" && "40px") ||
      (props.size === "large" && "50px")};
      height: ${(props.size === "small" && "30px") ||
      (props.size === "medium" && "40px") ||
      (props.size === "large" && "50px")};
      padding: 0; /* Remove default padding */
      font-size: ${(props.size === "small" && "12px") ||
      (props.size === "medium" && "16px") ||
      (props.size === "large" && "20px")}; /* Adjust icon size */
      border-radius: 50%; /* Keep circular shape for icons */
    `}

  /* Disabled State */
  ${(props) =>
    props.disabled &&
    css`
      background-color: #bdc3c7;
      color: #ecf0f1;
      cursor: not-allowed;
      pointer-events: none;
    `}

  /* Loading State */
  ${(props) =>
    props.loading &&
    css`
      cursor: wait;
      position: relative;

      &::after {
        content: "";
        position: absolute;
        width: 1rem;
        height: 1rem;
        border: 2px solid ${(props) => props.theme.colors.white};
        border-top: 2px solid ${(props) => props.theme.colors.primary};
        border-radius: 50%;
        animation: spin 1s linear infinite;
      }
    `}

  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
`;
