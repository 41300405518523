import React from "react";
import { Body, CloseIconButton, Content, Header, Overlay } from "./ModalStyles";

interface ModalProps {
  isOpen: boolean;
  onClose?: () => void;
  children: React.ReactNode;
  title?: string;
}

export const Modal: React.FC<ModalProps> = ({
  isOpen,
  onClose,
  children,
  title,
}) => {
  if (!isOpen) return null;

  return (
    <Overlay onClick={onClose}>
      <Content onClick={(e) => e.stopPropagation()}>
        <Header>
          {title}
          {onClose && <CloseIconButton onClick={onClose} />}
        </Header>
        <Body>{children}</Body>
      </Content>
    </Overlay>
  );
};
