import { Button } from "../../components/Button/Button";
import { Input } from "../../components/Input/Input";
import { Select } from "../../components/Select/Select";
import { AssetTypesConstants } from "../../constants";
import { IAsset } from "../../types";
import { ButtonContainer, Form } from "./AssetFormTemplateStyles";

export const AssetFormTemplate = ({
  newAsset,
  setNewAsset,
  errors,
  handleSave,
  handleChange,
  onClose,
}: {
  newAsset: IAsset;
  setNewAsset: any;
  errors: { [key: string]: string };
  handleSave: () => void;
  handleChange: (name: string, value: any) => void;
  onClose: () => void;
}) => {
  return (
    <Form style={{ maxWidth: "600px", margin: "0 auto" }}>
      <Input
        type="text"
        name="name"
        value={newAsset.name}
        onChange={handleChange}
        label="Name"
        errors={errors}
      />
      <Select
        name="assetType"
        value={newAsset.assetType}
        options={AssetTypesConstants.map((type) => ({
          label: type.displayName,
          value: type.value,
        }))}
        onChange={handleChange}
        label="Asset Type"
        errors={errors}
      />
      <Input
        type="number"
        name="quantity"
        value={newAsset.quantity.toString()}
        onChange={handleChange}
        label="Quantity"
        errors={errors}
      />
      <Input
        type="number"
        name="purchaseValue"
        value={newAsset.purchaseValue.toString()}
        onChange={handleChange}
        label="Purchase Value"
        errors={errors}
      />
      <Input
        type="number"
        name="currentValue"
        value={newAsset.currentValue.toString()}
        onChange={handleChange}
        label="Current Value"
        errors={errors}
      />
      <ButtonContainer
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginTop: "20px",
        }}
      >
        <Button variant="primary" size="medium" onClick={handleSave} flex>
          Save
        </Button>
        <Button variant="danger" size="medium" onClick={onClose} flex>
          Cancel
        </Button>
      </ButtonContainer>
    </Form>
  );
};
