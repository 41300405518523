import React, { useState } from "react";
import { Link } from "react-router-dom";
import { FaTable, FaChartBar } from "react-icons/fa";
import { useSelector, useDispatch } from "react-redux";

import { RootState, AppDispatch } from "../../redux/store";
import {
  setFilter,
  resetFilter,
  setGroupBy,
  setSorting,
} from "../../redux/slices/counterSlice";
import { groupDataGeneration } from "../../utils/dataUtils";
import { Select } from "../../components/Select/Select";
import { ChartView } from "../../components/Chart/Chart";
import { InvestmentTableTemplate } from "../../templates/InvestmentTable/InvestmentTable";

import "./GroupScreenStyles.css";

export const GroupScreen = () => {
  const [activeTab, setActiveTab] = useState("table"); // Toggle between "table" and "chart"
  const { groupBy, stocks } = useSelector((state: RootState) => state.data);
  const { sortBy, sortOrder } = useSelector(
    (state: RootState) => state.data.group
  );
  const dispatch = useDispatch<AppDispatch>();

  const generateReport = React.useMemo(() => {
    return groupDataGeneration({
      Data: stocks.list,
      groupBy,
    });
  }, [groupBy, stocks.list]);

  const nameResolver = React.useCallback(
    (value: any) => {
      return value ? (
        <Link
          onClick={() => {
            dispatch(resetFilter());
            dispatch(setFilter({ key: groupBy, value: value }));
          }}
          to={`/`}
        >
          {value}
        </Link>
      ) : (
        "N/A"
      );
    },
    [dispatch, groupBy]
  );

  const columns = {
    displayName: {
      header: "Name",
      accessor: "displayName",
      bodyResolver: nameResolver,
      sortable: true,
    },
  };

  const options = [
    { label: "Sector", value: "tagSector" },
    { label: "Market Cap", value: "tagMarketCap" },
    { label: "Growth Potential", value: "tagGrowthPotential" },
    { label: "Investment Horizon", value: "tagInvestmentHorizon" },
  ];

  const handleGroupChange = (name: string, value: any) => {
    dispatch(setGroupBy(value));
  };

  const handleSorting = (sortBy: string, sortOrder: "asc" | "desc") => {
    dispatch(setSorting({ category: "group", sortBy, sortOrder }));
  };

  return (
    <div className="group-screen-container">
      <div className="group-header">
        <h1>Groups</h1>
        <div style={{ display: "flex", alignItems: "center", gap: "15px" }}>
          <div className="view-toggle-buttons">
            <FaTable
              className={`toggle-icon ${activeTab === "table" ? "active" : ""}`}
              onClick={() => setActiveTab("table")}
              title="Table View"
            />
            <FaChartBar
              className={`toggle-icon ${activeTab === "chart" ? "active" : ""}`}
              onClick={() => setActiveTab("chart")}
              title="Chart View"
            />
          </div>
          <Select
            name="groupBy"
            label="Tag"
            value={groupBy}
            options={options}
            onChange={handleGroupChange}
          />
        </div>
      </div>

      {activeTab === "table" ? (
        <InvestmentTableTemplate
          data={generateReport.data}
          meta={generateReport.meta}
          sortBy={sortBy}
          sortOrder={sortOrder}
          setSorting={handleSorting}
          columnOverrides={columns}
        />
      ) : (
        <ChartView data={generateReport.data} />
      )}
    </div>
  );
};
