import React from "react";
import { GoogleSignInButton } from "../../presentationals/organisms/GoogleSignInButton";
import "./LoginScreenStyles.css";
import { siteConstants } from "../../constants/siteConstants";

export const LoginScreen = () => {
  return (
    <div className="login-container">
      <div className="login-overlay">
        <div className="login-box">
          <h1 className="login-title">Welcome to {siteConstants.title}</h1>
          <p className="login-subtitle">
            Log in to take control of your investments.
          </p>
          <GoogleSignInButton />
        </div>
      </div>
    </div>
  );
};
