import React from "react";
import { authService } from "../../services/authService";
import { FaGoogle } from "react-icons/fa";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../redux/store";
import { userActions } from "../../redux/actions";

export const GoogleSignInButton: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();

  const handleGoogleSignIn = async () => {
    try {
      const user = await authService.googleSignIn();
      dispatch(userActions.setUserInformation(user));
    } catch (error: any) {
      console.error("Google Sign-In failed:", error.message);
    }
  };

  return (
    <button className="google-button" onClick={handleGoogleSignIn}>
      <FaGoogle style={{ marginRight: "10px" }} />
      Sign in with Google
    </button>
  );
};
