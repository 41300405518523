import React from "react";
import { StyledButton } from "./ButtonStyles";

interface ButtonProps {
  variant?: "primary" | "secondary" | "danger" | "outline" | "text" | "icon";
  size?: "small" | "medium" | "large";
  rounded?: boolean;
  disabled?: boolean;
  loading?: boolean;
  flex?: boolean; // Flex support
  children: React.ReactNode;
  onClick?: () => void;
}

export const Button: React.FC<ButtonProps> = ({
  variant = "primary",
  size = "medium",
  rounded = false,
  disabled = false,
  loading = false,
  flex,
  children,
  onClick,
}) => {
  return (
    <StyledButton
      variant={variant}
      size={size}
      rounded={rounded}
      disabled={disabled}
      loading={loading}
      flex={flex}
      isIcon={variant === "icon"} // Additional prop for icon buttons
      onClick={onClick}
    >
      {loading ? "Loading..." : children}
    </StyledButton>
  );
};
