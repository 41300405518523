// src/redux/thunks/assetThunks.ts
import { AppDispatch, RootState } from "../store";
import { stocksService } from "../../services/stockServices";
import { populateStocks } from "../slices/counterSlice";

export const fetch =
  () => async (dispatch: AppDispatch, getState: () => RootState) => {
    const { uid, pin } = getState().user;
    try {
      const records = await stocksService.get({
        userId: uid,
        userPin: pin,
      });
      const parsed = JSON.parse(records);
      dispatch(populateStocks({ list: parsed }));
    } catch (error: any) {
      console.log(error);
    }
  };
