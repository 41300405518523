import { AppDispatch } from "../redux/store";
import { assetThunks, plannerThunks, stockThunks } from "../redux/thunks";

class AppService {
  /**
   * Initialize the app and trigger fetching assets for the authenticated user.
   * @param userId The ID of the authenticated user.
   * @param dispatch Redux dispatch method to update the store.
   */
  public init = async (dispatch: AppDispatch): Promise<void> => {
    try {
      await Promise.all([
        dispatch(assetThunks.fetchAssets()),
        dispatch(plannerThunks.fetch()),
        dispatch(stockThunks.fetch()),
      ]);
    } catch (error) {
      console.error("Error during app initialization:", error);
    }
  };
}

export const appService = new AppService();
