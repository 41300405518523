import React from "react";
import { NavLink } from "react-router-dom";
import {
  FaClipboardList,
  FaHome,
  FaUsers,
  FaSignOutAlt,
  FaMoneyCheck,
} from "react-icons/fa"; // Icons
import { authService } from "../../services/authService"; // Assuming you have an authService for logout
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../redux/store";
import { assetActions, userActions } from "../../redux/actions";

import {
  LayoutContainer,
  NavSection,
  NavHeader,
  NavList,
  NavItem,
  NavLink as StyledNavLink,
  MainSection,
  LogoutButton,
} from "./LayoutStyles";

export const Layout = ({ activityArea }: { activityArea: React.ReactNode }) => {
  const dispatch = useDispatch<AppDispatch>();

  const handleLogout = async () => {
    try {
      await authService.googleSignOut();
      dispatch(userActions.resetUserInformation());
      dispatch(assetActions.resetReducer());
    } catch (error: any) {
      console.error("Logout failed:", error.message);
    }
  };

  return (
    <LayoutContainer>
      <NavSection>
        <NavHeader>P</NavHeader> {/* Initial or small icon for branding */}
        <nav>
          <NavList>
            <NavItem>
              <StyledNavLink
                as={NavLink}
                to="/"
                className={({ isActive }: { isActive: boolean }) =>
                  isActive ? "active" : undefined
                }
                data-tooltip="Home"
              >
                <FaHome />
              </StyledNavLink>
            </NavItem>
            <NavItem>
              <StyledNavLink
                as={NavLink}
                to="/assets"
                className={({ isActive }: { isActive: boolean }) =>
                  isActive ? "active" : undefined
                }
                data-tooltip="Assets"
              >
                <FaMoneyCheck />
              </StyledNavLink>
            </NavItem>
            <NavItem>
              <StyledNavLink
                as={NavLink}
                to="/groups"
                className={({ isActive }: { isActive: boolean }) =>
                  isActive ? "active" : undefined
                }
                data-tooltip="Groups"
              >
                <FaUsers />
              </StyledNavLink>
            </NavItem>
            <NavItem>
              <StyledNavLink
                as={NavLink}
                to="/planning"
                className={({ isActive }: { isActive: boolean }) =>
                  isActive ? "active" : undefined
                }
                data-tooltip="Planning"
              >
                <FaClipboardList />
              </StyledNavLink>
            </NavItem>
          </NavList>
        </nav>
        <LogoutButton
          data-tooltip="Logout"
          onClick={handleLogout}
          title="Logout"
        >
          <FaSignOutAlt />
        </LogoutButton>
      </NavSection>
      <MainSection>{activityArea}</MainSection>
    </LayoutContainer>
  );
};
