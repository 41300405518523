export const formatToINR = (
  amount: number,
  abbreviate: boolean = false
): string => {
  const isNegative = amount < 0;
  const absoluteAmount = Math.abs(amount);

  let abbreviation = ""; // To hold 'CR', 'L', or 'K'
  let displayAmount = absoluteAmount; // The amount to format

  if (abbreviate) {
    if (absoluteAmount >= 1e7) {
      displayAmount = absoluteAmount / 1e7;
      abbreviation = " CR";
    } else if (absoluteAmount >= 1e5) {
      displayAmount = absoluteAmount / 1e5;
      abbreviation = " L";
    } else if (absoluteAmount >= 1e3) {
      displayAmount = absoluteAmount / 1e3;
      abbreviation = " K";
    }
  }

  // Format the amount
  const formattedAmount = new Intl.NumberFormat("en-IN", {
    style: "currency",
    currency: "INR",
    minimumFractionDigits: 2,
  }).format(displayAmount);

  // Add negative sign and abbreviation (if any)
  return (isNegative ? `-${formattedAmount}` : formattedAmount) + abbreviation;
};
