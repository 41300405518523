import { db } from "../libraries/firebaseLibrary";
import {
  collection,
  getDocs,
  addDoc,
  deleteDoc,
  doc,
  updateDoc,
} from "firebase/firestore";
import {
  EncryptedDocument,
  IAsset,
  IAssetDocument,
  IAssetDocumentList,
} from "../types";
import { decryptData, encryptData } from "../utils/encryptionUtils";

class AssetService {
  private getCollectionPath(userId: string) {
    return `users/${userId}/assets`;
  }

  /**
   * Fetch all assets for a user.
   */
  public fetchAssets = async ({
    userId,
    userPin,
  }: {
    userId: string;
    userPin: string;
  }): Promise<IAssetDocumentList> => {
    try {
      const assetsCollection = collection(db, this.getCollectionPath(userId));
      const querySnapshot = await getDocs(assetsCollection);
      return querySnapshot.docs.map((doc) => {
        const decryptedData = decryptData(doc.data().encryptedData, userPin);
        return {
          id: doc.id,
          ...decryptedData,
        };
      }) as IAssetDocumentList;
    } catch (error: any) {
      console.error("Error fetching assets:", error.message);
      throw error;
    }
  };

  /**
   * Add a new asset for a user.
   */
  public addAsset = async ({
    userId,
    userPin,
    asset,
  }: {
    userId: string;
    userPin: string;
    asset: IAsset;
  }): Promise<IAssetDocument> => {
    try {
      const newData = { ...asset };
      delete newData.id;

      const encryptedData = encryptData(newData, userPin);
      const encryptedDocument: EncryptedDocument = {
        encryptedData,
        timestamp: Date.now(),
      };

      const assetsCollection = collection(db, this.getCollectionPath(userId));
      const docRef = await addDoc(assetsCollection, encryptedDocument);
      return { ...asset, id: docRef.id };
    } catch (error: any) {
      console.error("Error adding asset:", error.message);
      throw error;
    }
  };

  /**
   * Delete an asset for a user.
   */
  public deleteAsset = async (userId: string, id: string): Promise<void> => {
    try {
      const assetRef = doc(db, this.getCollectionPath(userId), id);
      await deleteDoc(assetRef);
    } catch (error: any) {
      console.error("Error deleting asset:", error.message);
      throw error;
    }
  };

  /**
   * Update an existing asset for a user.
   */
  public updateAsset = async ({
    userId,
    userPin,
    id,
    updatedAsset,
  }: {
    userId: string;
    userPin: string;
    id: string;
    updatedAsset: Partial<IAssetDocument>;
  }): Promise<void> => {
    try {
      const updateData = { ...updatedAsset };
      delete updateData.id;

      const encryptedData = encryptData(updateData, userPin);
      const encryptedDocument: EncryptedDocument = {
        encryptedData,
        timestamp: Date.now(),
      };

      const assetRef = doc(db, this.getCollectionPath(userId), id);
      await updateDoc(assetRef, encryptedDocument as { [key: string]: any });
    } catch (error: any) {
      console.error("Error updating asset:", error.message);
      throw error;
    }
  };
}

export const assetService = new AssetService();
