// firebaseConfig.js
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";

// Replace with your Firebase project configuration
const firebaseConfig = {
  apiKey: "AIzaSyBr_roZ-sl6ejpFFqaFO4p71sM6RAZsRXU",
  authDomain: "portfolio-analytical.firebaseapp.com",
  projectId: "portfolio-analytical",
  storageBucket: "portfolio-analytical.firebasestorage.app",
  messagingSenderId: "1094793395506",
  appId: "1:1094793395506:web:283f7dca601f6d379b2b2b",
  measurementId: "G-80NGC0YWHQ",
};

const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const db = getFirestore(app);
