import { useEffect, useState } from "react";
import * as yup from "yup";
import { useDispatch } from "react-redux";

import { IAssetDocument } from "../../types";
import { AppDispatch, assetThunks } from "../../redux";
import { AssetFormTemplate } from "../../templates";

const assetSchema: any = yup.object().shape({
  name: yup.string().required("Name is required"),
  assetType: yup.string().required("Asset type is required"),
  quantity: yup
    .number()
    .required("Quantity is required")
    .min(1, "Quantity must be greater than 0"),
  purchaseValue: yup
    .number()
    .required("Purchase value is required")
    .min(1, "Purchase value must be greater than 0"),
  currentValue: yup
    .number()
    .required("Current value is required")
    .min(1, "Current value must be greater than 0"),
});

type AddAction = {
  action: "add";
  id?: never;
  data?: never;
  onClose: () => void;
  onSave: (asset: any) => void;
};

type EditAction = {
  action: "edit";
  data: IAssetDocument;
  onClose: () => void;
  onSave: (asset: any) => void;
};

type AssetActionProps = AddAction | EditAction;

export const AssetFormScreen = ({
  action,
  data,
  onClose,
  onSave,
}: AssetActionProps) => {
  const dispatch = useDispatch<AppDispatch>();
  const [asset, setAsset] = useState<IAssetDocument>({
    id: "",
    name: "",
    assetType: "",
    quantity: 0,
    purchaseValue: 0,
    currentValue: 0,
  });

  const [errors, setErrors] = useState<{ [key: string]: string }>({});

  useEffect(() => {
    if (action === "edit") {
      setAsset(data);
    }
  }, [action, data]);

  const handleChange = async (name: string, value: any) => {
    setAsset({ ...asset, [name]: value });
    if (Object.keys(errors).includes(name)) {
      try {
        await assetSchema.fields[name]?.validate(value);
        setErrors((prevErrors) => ({ ...prevErrors, [name]: "" })); // Clear the error
      } catch (err: any) {
        if (err instanceof yup.ValidationError) {
          setErrors((prevErrors) => ({
            ...prevErrors,
            [name]: err.message,
          }));
        }
      }
    }
  };

  const handleSave = async () => {
    try {
      await assetSchema.validate(asset, { abortEarly: false });
      if (action === "add") {
        dispatch(assetThunks.addAsset(asset));
      } else if (action === "edit") {
        dispatch(assetThunks.updateAsset(asset.id, asset));
      }
      onSave(asset);
    } catch (err) {
      if (err instanceof yup.ValidationError) {
        const validationErrors: { [key: string]: string } = {};
        err.inner.forEach((error) => {
          if (error.path) {
            validationErrors[error.path] = error.message;
          }
        });
        setErrors(validationErrors);
      }
    }
  };

  return (
    <AssetFormTemplate
      newAsset={asset}
      setNewAsset={setAsset}
      errors={errors}
      handleSave={handleSave}
      handleChange={handleChange}
      onClose={onClose}
    />
  );
};
