export const themeStyle = {
  colors: {
    primary: "#1abc9c", // Main accent color (e.g., for active links)
    secondary: "#2c3e50", // Secondary background color (e.g., navigation)
    background: "#ecf0f1", // Light gray background
    white: "#ffffff", // White color for text and backgrounds
    danger: "#e74c3c", // For error states or alerts
    text: "#333", // Default text color
  },
  typography: {
    fontFamily: "'Arial', sans-serif", // Default font family
    fontSize: {
      small: "14px", // For tooltips or small text
      medium: "16px", // For regular text
      large: "18px", // For headers or larger text
    },
    fontWeight: {
      normal: 400, // Regular text
      bold: 700, // Bold text
    },
  },
  spacing: {
    small: "8px", // Small spacing for padding/margin
    medium: "16px", // Medium spacing for padding/margin
    large: "24px", // Large spacing for padding/margin
  },
  shadows: {
    light: "0 4px 6px rgba(0, 0, 0, 0.1)", // Light shadow for subtle elements
    medium: "0 6px 10px rgba(0, 0, 0, 0.15)", // Medium shadow for modals/cards
    strong: "0 8px 15px rgba(0, 0, 0, 0.2)", // Strong shadow for prominent elements
  },
  borderRadius: {
    small: "4px", // For tooltips or small elements
    medium: "8px", // For buttons or moderate elements
    large: "12px", // For cards or large elements
  },
};
