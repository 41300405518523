import React from "react";
import {
  ResponsiveContainer,
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  CartesianGrid,
  Cell,
} from "recharts";

export const ChartView = ({ data }: { data: any[] }) => {
  const chartData = data.map((row) => ({
    name: row.name,
    Invested: row.holdingInvestedAmount || 0,
    Current: row.holdingLiveAmount || 0,
    PnL: row.holdingProfitAndLoss || 0,
  }));

  return (
    <div style={{ width: "100%", height: "600px" }}>
      <ResponsiveContainer>
        <BarChart
          data={chartData}
          margin={{ top: 20, right: 30, left: 20, bottom: 150 }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            dataKey="name"
            angle={-35} // Rotate labels
            textAnchor="end"
            interval={0} // Show all labels
          />
          <YAxis />
          <Tooltip
            formatter={(value: number, name: string) => [
              `₹${value.toLocaleString()}`,
              name,
            ]}
            contentStyle={{
              backgroundColor: "#f5f5f5",
              borderRadius: "8px",
              border: "1px solid #ddd",
            }}
          />
          <Legend verticalAlign="top" wrapperStyle={{ marginBottom: "0px" }} />

          {/* Invested Bar */}
          <Bar dataKey="Invested" fill="#8884d8" radius={[5, 5, 0, 0]} />

          {/* Current Value Bar */}
          <Bar dataKey="Current" fill="#82ca9d" radius={[5, 5, 0, 0]} />

          {/* PnL Bar with Conditional Coloring */}
          <Bar dataKey="PnL" radius={[5, 5, 0, 0]}>
            {chartData.map((entry, index) => (
              <Cell
                key={`cell-${index}`}
                fill={entry.PnL > 0 ? "#82ca9d" : "#ff6f61"} // Green for profit, Red for loss
              />
            ))}
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};
