import styled from "styled-components";

export const InputContainer = styled.div`
  position: relative;
  min-width: 240px;
  flex: 1;
  font-family: "Poppins", sans-serif;
  margin-bottom: 20px; /* Reserve space for the error message */
`;

export const Label = styled.label`
  position: absolute;
  top: 12px;
  left: 15px;
  font-size: 14px;
  color: #666;
  background: #ffffff;
  padding: 0 5px;
  transition: all 0.3s ease;
  pointer-events: none;
  z-index: 1;

  &.focused {
    top: -10px;
    left: 10px;
    font-size: 12px;
    color: #007bff;
    background: #ffffff;
  }
`;

export const InputField = styled.input`
  width: 94%;
  padding: 14px 15px;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 14px;
  color: #333;
  background-color: #ffffff;
  transition: border-color 0.3s ease, box-shadow 0.3s ease;

  &:focus {
    border-color: #007bff;
    box-shadow: 0 2px 8px rgba(0, 123, 255, 0.2);
    outline: none;
  }
`;

export const Placeholder = styled.span`
  font-size: 14px;
  color: #666;
  transition: color 0.3s ease;

  &.focused {
    color: #333;
  }
`;

export const ErrorMessage = styled.div`
  margin-top: 5px;
  font-size: 12px;
  color: ${(props) => props.theme.colors.danger};
  position: absolute;
  left: 0;
  bottom: -18px;
`;
