import { Table } from "../../components/Table/Table";
import { formatToINR } from "../../utils/currencyUtils";

export const InvestmentTableTemplate = ({
  data,
  meta,
  sortBy,
  sortOrder,
  columnOverrides = {},
  setSorting,
}: {
  data: any[];
  meta: any;
  columnOverrides?: {
    [key: string]: any;
  };
  sortBy?: string | null;
  sortOrder?: "asc" | "desc";
  setSorting?: (sortBy: string, sortOrder: "asc" | "desc") => void;
}) => {
  const resolverInvestment = (value: any, row: any, accessor: string) => {
    // Avoid division by zero or invalid meta data
    const totalAmount = meta[accessor] || 0;
    if (!value || totalAmount === 0) {
      return "N/A";
    }

    // Calculate percentage
    let percent = "N/A";
    if (accessor === "holdingProfitAndLoss") {
      const invested = row.holdingInvestedAmount || 0;
      const currentValue = row.holdingLiveAmount || 0;
      if (invested > 0) {
        percent = (((currentValue - invested) / invested) * 100).toFixed(2);
      }
    } else {
      percent = ((row[accessor] / totalAmount) * 100).toFixed(2);
    }

    // Determine color for percentage (specific to PnL)
    let percentColor = "gray";
    let backgroundColor = "#f9f9f9"; // Default light gray background
    if (accessor === "holdingProfitAndLoss") {
      if (value > 0) {
        percentColor = "green";
        backgroundColor = "#e6f4e6"; // Light green tint
      } else if (value < 0) {
        percentColor = "red";
        backgroundColor = "#fce6e6"; // Light red tint
      }
    }

    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: "4px",
          padding: "8px",
          borderRadius: "5px",
          backgroundColor,
        }}
        title={`${formatToINR(value)} (${percent}%)`}
      >
        <span style={{ fontWeight: "bold", fontSize: "14px" }}>
          {formatToINR(value)}
        </span>
        <span
          style={{
            fontSize: "12px",
            color: percentColor,
            fontStyle: "italic",
          }}
        >
          {percent}%
        </span>
      </div>
    );
  };

  const defaultColumns: any = [
    { header: "Name", accessor: "displayName", sortable: true },
    {
      header: "Holdings",
      accessor: "holdingQuantity",
      align: "center",
      sortable: true,
    },
    {
      header: "Invested",
      accessor: "holdingInvestedAmount",
      align: "center",
      bodyResolver: (value: any, row: any) =>
        resolverInvestment(value, row, "holdingInvestedAmount"),
      resolver: (value: number) => formatToINR(value),
      sortable: true,
    },
    {
      header: "Current Value",
      accessor: "holdingLiveAmount",
      align: "center",
      bodyResolver: (value: any, row: any) =>
        resolverInvestment(value, row, "holdingLiveAmount"),
      resolver: (value: number) => formatToINR(value),
      sortable: true,
    },
    {
      header: "PnL",
      accessor: "holdingProfitAndLoss",
      align: "center",
      bodyResolver: (value: any, row: any) =>
        resolverInvestment(value, row, "holdingProfitAndLoss"),
      resolver: (value: number) => formatToINR(value),
      sortable: true,
    },
  ];

  // Apply column overrides
  const columns = defaultColumns.map((column: any) => {
    return {
      ...column,
      ...(columnOverrides[column.accessor] || {}),
    };
  });

  return (
    <Table
      data={data}
      columns={columns}
      meta={meta}
      sortBy={sortBy}
      sortOrder={sortOrder}
      setSorting={setSorting}
    />
  );
};
