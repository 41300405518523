import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IAsset, IAssetDocument, IAssetList } from "../../types/assetTypes";

interface CounterState {
  assets: IAssetList;
  loading: boolean;
  error: string | null;
}
const defaultReducer = { assets: [], loading: false, error: null };

const initialState: CounterState = { ...defaultReducer };

export const counterSlice = createSlice({
  name: "assets",
  initialState,
  reducers: {
    addAsset: (state, action: PayloadAction<{ asset: IAsset }>) => {
      state.assets.push(action.payload.asset);
    },
    removeAsset: (state, action: PayloadAction<{ asset: IAsset }>) => {
      state.assets.push(action.payload.asset);
    },
    fetchAssetsStart(state) {
      state.loading = true;
      state.error = null;
    },
    fetchAssetsSuccess(state, action: PayloadAction<IAssetList>) {
      state.assets = action.payload;
      state.loading = false;
    },
    fetchAssetsFailure(state, action: PayloadAction<string>) {
      state.loading = false;
      state.error = action.payload;
    },
    addAssetSuccess(state, action: PayloadAction<IAsset>) {
      state.assets.push(action.payload);
    },
    updateAssetSuccess(state, action: PayloadAction<IAssetDocument>) {
      const index = state.assets.findIndex(
        (a: any) => a.id === action.payload.id
      );
      if (index >= 0) {
        state.assets[index] = { ...state.assets[index], ...action.payload };
      }
    },
    deleteAssetSuccess(state, action: PayloadAction<string>) {
      state.assets = state.assets.filter((a: any) => a.id !== action.payload);
    },
    resetReducer(state) {
      state.assets = defaultReducer.assets;
      state.error = defaultReducer.error;
      state.loading = defaultReducer.loading;
    },
  },
});

export const {
  addAsset,
  removeAsset,
  fetchAssetsStart,
  fetchAssetsSuccess,
  fetchAssetsFailure,
  addAssetSuccess,
  updateAssetSuccess,
  deleteAssetSuccess,
  resetReducer,
} = counterSlice.actions;

export default counterSlice.reducer;
