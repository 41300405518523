import styled, { css } from "styled-components";

export const SelectContainer = styled.div<{
  isFocused: boolean;
  hasValue: boolean;
}>`
  position: relative;
  min-width: 240px;
  flex: 1;
  font-family: "Poppins", sans-serif;
  cursor: pointer;
  margin-bottom: 40px; /* Reserve space for the error message */
  ${(props) =>
    props.isFocused &&
    css`
      .select-display {
        border-color: ${props.theme.colors.primary};
        box-shadow: 0 2px 8px rgba(0, 123, 255, 0.2);
      }
    `}
`;

export const SelectLabel = styled.label<{ isFocused: boolean }>`
  position: absolute;
  top: 12px;
  left: 15px;
  font-size: 14px;
  color: #666;
  background: #ffffff;
  padding: 0 5px;
  transition: all 0.3s ease;
  pointer-events: none;

  ${(props) =>
    props.isFocused &&
    css`
      top: -10px;
      left: 10px;
      font-size: 12px;
      color: ${props.theme.colors.primary};
    `}
`;

export const SelectDisplay = styled.div`
  padding: 14px 15px;
  border: 1px solid #ddd;
  border-radius: 5px;
  background-color: #ffffff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
`;

export const SelectPlaceholder = styled.span<{ hasValue: boolean }>`
  font-size: 14px;
  color: ${(props) => (props.hasValue ? "#333" : "#666")};
  transition: color 0.3s ease;
`;

export const DropdownArrow = styled.span`
  font-size: 12px;
  color: #333;
`;

export const SelectDropdown = styled.div`
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background-color: #ffffff;
  border: 1px solid #ddd;
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-top: 5px;
  max-height: 200px;
  overflow-y: auto;
  animation: fadeIn 0.2s ease;
  z-index: 1000;

  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
`;

export const SelectOption = styled.div<{ isSelected: boolean }>`
  padding: 12px 15px;
  font-size: 14px;
  color: ${(props) => (props.isSelected ? "#ffffff" : "#333")};
  background-color: ${(props) =>
    props.isSelected ? props.theme.colors.primary : "#ffffff"};
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;

  &:hover {
    background-color: ${(props) => props.theme.colors.secondary};
    color: #ffffff;
  }
`;

export const ErrorMessage = styled.div`
  margin-top: 5px;
  font-size: 12px;
  color: ${(props) => props.theme.colors.danger};
  position: absolute;
  left: 0;
  bottom: -18px;
`;
