/* AddAssetModalStyles.css */
import styled from "styled-components";

// Styled Components
export const Form = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${(props) => props.theme.spacing.medium};
`;

export const ButtonContainer = styled.div`
  display: flex;
  gap: ${(props) => props.theme.spacing.medium};
  justify-content: center;
`;
