import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IPlannerDocument, IPlannerDocumentList } from "../../types";
import { plannerLabelsObject, stocksWithKeys } from "../../utils/dataUtils";

interface CounterState {
  home: {
    sortBy: string;
    sortOrder: "asc" | "desc";
  };
  asset: {
    sortBy: string;
    sortOrder: "asc" | "desc";
  };
  group: {
    sortBy: string;
    sortOrder: "asc" | "desc";
  };
  groupBy: string;
  planning: IPlannerDocumentList;
  planningObject: { [code: string]: IPlannerDocument };
  stocks: {
    list: any;
    map: any;
  };
  filters: {
    tagMarketCap: string;
    tagSector: string;
    tagInvestmentHorizon: string;
    tagGrowthPotential: string;
    [key: string]: string;
  };
}

const initialState: CounterState = {
  home: {
    sortBy: "displayName",
    sortOrder: "asc",
  },
  asset: {
    sortBy: "name",
    sortOrder: "asc",
  },
  group: {
    sortBy: "displayName",
    sortOrder: "asc",
  },
  groupBy: "tagSector",
  planning: [],
  planningObject: {},
  stocks: {
    list: [],
    map: [],
  },
  filters: {
    tagMarketCap: "",
    tagSector: "",
    tagInvestmentHorizon: "",
    tagGrowthPotential: "",
  },
};

export const counterSlice = createSlice({
  name: "data",
  initialState,
  reducers: {
    setSorting: (
      state,
      action: PayloadAction<{
        category: "home" | "group" | "asset";
        sortBy: string;
        sortOrder: "asc" | "desc";
      }>
    ) => {
      if (action.payload.category === "home") {
        state.home.sortBy = action.payload.sortBy;
        state.home.sortOrder = action.payload.sortOrder;
      } else if (action.payload.category === "group") {
        state.group.sortBy = action.payload.sortBy;
        state.group.sortOrder = action.payload.sortOrder;
      } else if (action.payload.category === "asset") {
        state.asset.sortBy = action.payload.sortBy;
        state.asset.sortOrder = action.payload.sortOrder;
      }
    },
    resetSorting: (state) => {
      state.home.sortBy = "displayName";
      state.home.sortOrder = "asc";
      state.group.sortBy = "displayName";
      state.group.sortOrder = "asc";
      state.asset.sortBy = "name";
      state.asset.sortOrder = "asc";
    },
    setGroupBy: (state, action: PayloadAction<string>) => {
      state.groupBy = action.payload;
    },
    resetGroupBy: (state) => {
      state.groupBy = "tagSector";
    },
    populateStocks: (state, action: PayloadAction<{ list: any }>) => {
      state.stocks.list = action.payload.list;
      state.stocks.map = stocksWithKeys(action.payload.list);
    },
    populatePlanning: (
      state,
      action: PayloadAction<{ planning: IPlannerDocumentList }>
    ) => {
      state.planning = action.payload.planning;
      state.planningObject = plannerLabelsObject(action.payload.planning);
    },
    addToPlanning: (state, action: PayloadAction<IPlannerDocument>) => {
      state.planning.push(action.payload);
      state.planningObject = {
        ...state.planningObject,
        [action.payload.code]: action.payload,
      };
    },
    updatePlanning: (
      state,
      action: PayloadAction<{ id: string; data: IPlannerDocument }>
    ) => {
      state.planning = state.planning.map((p: any) => {
        if (p.id === action.payload.id) {
          return { ...p, ...action.payload.data };
        }
        return p;
      });
      const newObject = { ...state.planningObject };
      newObject[action.payload.id] = action.payload.data;
      state.planningObject = { ...newObject };
    },
    removeFromPlanning: (state, action: PayloadAction<{ id: string }>) => {
      state.planning = state.planning.filter(
        (p: any) => p.id !== action.payload.id
      );
      const newObject = { ...state.planningObject };
      delete newObject[action.payload.id];
      state.planningObject = { ...newObject };
    },
    setFilter: (
      state,
      action: PayloadAction<{ key: string; value: string }>
    ) => {
      const { key, value } = action.payload;
      state.filters[key] = value;
    },
    resetFilter: (state) => {
      state.filters = {
        tagMarketCap: "",
        tagSector: "",
        tagInvestmentHorizon: "",
        tagGrowthPotential: "",
      };
    },
  },
});

export const {
  setSorting,
  resetSorting,
  setGroupBy,
  resetGroupBy,
  populateStocks,
  populatePlanning,
  addToPlanning,
  updatePlanning,
  removeFromPlanning,
  setFilter,
  resetFilter,
} = counterSlice.actions;

export default counterSlice.reducer;
