import styled from "styled-components";

export const GroupContainer = styled.div`
  margin-bottom: 20px;
  border-radius: ${(props) => props.theme.borderRadius.medium || "8px"};
  overflow: hidden;
  background-color: ${(props) => props.theme.colors.white || "#fff"};

  @media (max-width: 768px) {
    margin-bottom: 15px;
  }
`;

export const GroupHeader = styled.div`
  display: flex;
  flex: 1;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  background-color: ${(props) =>
    props.theme.colors.backgroundSecondary || "#f9f9f9"};
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
  padding: 15px;

  &:hover {
    background-color: ${(props) =>
      props.theme.colors.primaryLight || "#eef5ff"};
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }

  @media (max-width: 768px) {
    flex-wrap: wrap; /* Allows wrapping without forcing column layout */
    padding: 10px;
  }

  h3 {
    margin: 0;
    font-size: ${(props) => props.theme.typography.fontSize.medium || "18px"};
    color: ${(props) => props.theme.colors.textPrimary || "#333"};

    @media (max-width: 768px) {
      font-size: ${(props) => props.theme.typography.fontSize.small || "16px"};
    }
  }
`;

export const HeaderContent = styled.div`
  display: flex;
  flex: 1;
  justify-content: space-between;
  align-items: center;
  padding: 15px 20px;
  background-color: ${(props) =>
    props.theme.colors.backgroundSecondary || "#f9f9f9"};
  border: 1px solid ${(props) => props.theme.colors.border || "#ddd"};
  border-radius: ${(props) => props.theme.borderRadius.medium || "8px"};
  cursor: pointer;
  transition: background-color 0.3s ease;

  @media (max-width: 768px) {
    flex-wrap: wrap; /* Prevents full column stacking */
    padding: 10px;
  }
`;

export const LeftSection = styled.div`
  flex: 1;

  h3 {
    margin: 0;
    font-size: ${(props) => props.theme.typography.fontSize.medium || "18px"};
    color: ${(props) => props.theme.colors.textPrimary || "#333"};
  }
`;

export const RightSection = styled.div`
  flex-shrink: 0;
  text-align: right;

  strong {
    font-size: ${(props) => props.theme.typography.fontSize.medium || "16px"};
    color: ${(props) => props.theme.colors.textPrimary || "#333"};
  }
`;

export const AccordionIcon = styled.div`
  font-size: ${(props) => props.theme.typography.fontSize.large || "20px"};
  color: ${(props) => props.theme.colors.primary || "#007bff"};
  margin-left: 15px;
  transition: transform 0.3s ease;
`;

export const Summary = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 15px;
  margin-top: 5px;

  @media (max-width: 768px) {
    flex-wrap: wrap; /* Avoid column stacking, wrap instead */
    justify-content: space-between;
  }

  span {
    font-size: ${(props) => props.theme.typography.fontSize.small || "14px"};
    color: ${(props) => props.theme.colors.textSecondary || "#666"};

    @media (max-width: 768px) {
      font-size: ${(props) => props.theme.typography.fontSize.xsmall || "12px"};
    }
  }

  strong {
    font-size: ${(props) => props.theme.typography.fontSize.medium || "16px"};
    color: ${(props) => props.theme.colors.textPrimary || "#333"};

    @media (max-width: 768px) {
      font-size: ${(props) => props.theme.typography.fontSize.small || "14px"};
    }
  }
`;

export const SummaryItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center; /* Ensures text stays aligned horizontally */
  width: 100%;

  @media (max-width: 768px) {
    justify-content: space-between; /* Keeps span and strong side by side */
  }

  span {
    margin-right: auto; /* Ensures span stays on the left */
  }
`;

export const AssetListHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;

  @media (max-width: 768px) {
    flex-wrap: wrap;
    gap: 10px;
  }

  h1 {
    font-size: ${(props) => props.theme.typography.fontSize.large || "24px"};
    color: ${(props) => props.theme.colors.textPrimary || "#333"};

    @media (max-width: 768px) {
      font-size: ${(props) => props.theme.typography.fontSize.medium || "18px"};
    }
  }
`;

export const ActionsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;

  @media (max-width: 768px) {
    gap: 8px;
  }
`;

export const GroupContent = styled.div`
  padding: 15px;
  background-color: ${(props) =>
    props.theme.colors.backgroundPrimary || "#fff"};
  border-top: 1px solid ${(props) => props.theme.colors.border || "#ddd"};

  div {
    /* margin-bottom: 10px; */

    p {
      margin: 0;
      font-size: ${(props) => props.theme.typography.fontSize.small || "14px"};
      line-height: 1.4;
      color: ${(props) => props.theme.colors.textSecondary || "#555"};

      strong {
        color: ${(props) => props.theme.colors.textPrimary || "#333"};
      }
    }
  }
`;

export const IconContainer = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  margin-right: 10px;
  color: ${(props) => props.theme.colors.primary || "#007bff"};

  @media (max-width: 768px) {
    margin-right: 5px;
  }
`;
