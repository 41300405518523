import { db } from "../libraries/firebaseLibrary";
import {
  collection,
  getDocs,
  addDoc,
  deleteDoc,
  doc,
  updateDoc,
  getDoc,
} from "firebase/firestore";
import { decryptData, encryptData } from "../utils/encryptionUtils";

export class FirestoreService<T> {
  constructor(private collectionPathProvider: (userId: string) => string) {}

  public get = async ({
    userId,
    userPin,
  }: {
    userId: string;
    userPin: string;
  }): Promise<T> => {
    const collectionPath = this.collectionPathProvider(userId);

    const userDocRef = doc(db, collectionPath); // Reference the user document
    const userDocSnapshot = await getDoc(userDocRef);

    if (userDocSnapshot.exists()) {
      // const decryptedData = decryptData(userDocSnapshot.data().stocks, userPin);
      return userDocSnapshot.data().stocks as T;
    }

    throw new Error("User document not found");
  };

  /**
   * Fetch all documents for a user.
   */
  public fetch = async ({
    userId,
    userPin,
  }: {
    userId: string;
    userPin: string;
  }): Promise<T[]> => {
    try {
      const collectionPath = this.collectionPathProvider(userId);
      const docsCollection = collection(db, collectionPath);
      const querySnapshot = await getDocs(docsCollection);

      return querySnapshot.docs.map((doc) => {
        const decryptedData = decryptData(doc.data().encryptedData, userPin);
        return {
          id: doc.id,
          ...decryptedData,
        } as T;
      });
    } catch (error: any) {
      console.error("Error fetching documents:", error.message);
      throw error;
    }
  };

  /**
   * Add a new document for a user.
   */
  public add = async ({
    userId,
    userPin,
    data,
  }: {
    userId: string;
    userPin: string;
    data: T;
  }): Promise<T & { id: string }> => {
    try {
      const newData = { ...data } as any;
      delete newData.id;

      const encryptedData = encryptData(newData, userPin);
      const encryptedDocument = {
        encryptedData,
        timestamp: Date.now(),
      };

      const collectionPath = this.collectionPathProvider(userId);
      const docsCollection = collection(db, collectionPath);
      const docRef = await addDoc(docsCollection, encryptedDocument);

      return { ...data, id: docRef.id } as T & { id: string };
    } catch (error: any) {
      console.error("Error adding document:", error.message);
      throw error;
    }
  };

  /**
   * Delete a document for a user.
   */
  public delete = async (userId: string, id: string): Promise<void> => {
    try {
      const collectionPath = this.collectionPathProvider(userId);
      const docRef = doc(db, collectionPath, id);
      await deleteDoc(docRef);
    } catch (error: any) {
      console.error("Error deleting document:", error.message);
      throw error;
    }
  };

  /**
   * Update an existing document for a user.
   */
  public update = async ({
    userId,
    userPin,
    id,
    updatedData,
  }: {
    userId: string;
    userPin: string;
    id: string;
    updatedData: Partial<T>;
  }): Promise<void> => {
    try {
      const updateData = { ...updatedData } as any;
      delete updateData.id;

      const encryptedData = encryptData(updateData, userPin);
      const encryptedDocument = {
        encryptedData,
        timestamp: Date.now(),
      };

      const collectionPath = this.collectionPathProvider(userId);
      const docRef = doc(db, collectionPath, id);
      await updateDoc(docRef, encryptedDocument as { [key: string]: any });
    } catch (error: any) {
      console.error("Error updating document:", error.message);
      throw error;
    }
  };
}
