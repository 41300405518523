import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface userState {
  uid: string;
  displayName: string | null;
  email: string | null;
  pin: string;
  pinError: boolean;
}
const defaultReducer = {
  uid: "",
  displayName: "",
  email: "",
  pin: "",
  pinError: false,
};

const initialState: userState = { ...defaultReducer };

export const counterSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUserInformation: (
      state,
      action: PayloadAction<Omit<userState, "pin" | "pinError">>
    ) => {
      state.uid = action.payload.uid;
      state.displayName = action.payload.displayName;
      state.email = action.payload.email;
    },
    setUserPincode: (state, action: PayloadAction<{ pin: string }>) => {
      state.pinError = false;
      state.pin = action.payload.pin;
    },
    setInvalidPin: (state) => {
      state.pinError = true;
    },
    resetUserInformation: (state) => {
      state.uid = defaultReducer.uid;
      state.displayName = defaultReducer.displayName;
      state.email = defaultReducer.email;
      state.pin = defaultReducer.pin;
      state.pinError = defaultReducer.pinError;
    },
  },
});

export const {
  setUserInformation,
  setUserPincode,
  setInvalidPin,
  resetUserInformation,
} = counterSlice.actions;

export default counterSlice.reducer;
