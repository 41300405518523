import React from "react";
import "./TableStyles.css";

interface TableProps {
  data: any[];
  meta?: any;
  columns: {
    header: string;
    accessor: string;
    align?: "center" | "justify" | "left" | "right" | "char" | any;
    bodyResolver?: (value: any, row?: any) => any;
    resolver?: (value: any, row?: any) => any;
    sortable?: boolean; // Indicates if the column is sortable
  }[];
  sortBy?: string | null;
  sortOrder?: "asc" | "desc";
  setSorting?: (sortBy: string, sortOrder: "asc" | "desc") => void;
  loading?: boolean; // Indicates if data is being fetched
  emptyPlaceholder?: string; // Placeholder text for empty data
  emptyImage?: string; // URL or import of the image for empty state
}

export const Table = ({
  data,
  meta,
  columns,
  sortBy = null,
  sortOrder = "asc",
  setSorting,
  loading = false,
  emptyPlaceholder = "No data available.",
  emptyImage = "https://openmoji.org/data/color/svg/1F4D6.svg",
}: TableProps) => {
  // Sort data when sortBy changes
  const sortedData = React.useMemo(() => {
    if (!sortBy) return data;
    return [...data].sort((a, b) => {
      const valueA = a[sortBy];
      const valueB = b[sortBy];
      if (valueA < valueB) return sortOrder === "asc" ? -1 : 1;
      if (valueA > valueB) return sortOrder === "asc" ? 1 : -1;
      return 0;
    });
  }, [data, sortBy, sortOrder]);

  const handleSort = (accessor: string) => {
    if (!setSorting) return;
    if (sortBy === accessor) {
      setSorting(sortBy, sortOrder === "asc" ? "desc" : "asc");
    } else {
      setSorting(accessor, "asc");
    }
  };

  if (loading) {
    return (
      <div className="table-placeholder">
        <p>Loading...</p>
      </div>
    );
  }

  if (data.length === 0) {
    return (
      <div className="table-placeholder">
        {emptyImage && <img src={emptyImage} alt="Empty state" />}
        <p>{emptyPlaceholder}</p>
      </div>
    );
  }

  return (
    <div className="table-container">
      <div className="table-wrapper">
        <table className="table">
          <thead>
            <tr>
              {columns.map((col) => (
                <th
                  key={col.accessor}
                  align={col.align ?? "left"}
                  onClick={
                    col.sortable ? () => handleSort(col.accessor) : undefined
                  }
                  style={{
                    cursor: col.sortable ? "pointer" : "default",
                    userSelect: "none",
                  }}
                >
                  {col.header}
                  {col.sortable && sortBy === col.accessor && (
                    <span style={{ marginLeft: "5px" }}>
                      {sortOrder === "asc" ? "↑" : "↓"}
                    </span>
                  )}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {sortedData.map((row, index) => (
              <tr key={index}>
                {columns.map((col) => {
                  const resolver = col.bodyResolver ?? col.resolver;
                  const value = resolver
                    ? resolver(row[col.accessor], row)
                    : String(row[col.accessor] || "");
                  return (
                    <td
                      key={`${col.accessor}-${index}`}
                      align={col.align ?? "left"}
                    >
                      {value}
                    </td>
                  );
                })}
              </tr>
            ))}
          </tbody>
          {meta && (
            <tfoot>
              <tr>
                {columns.map((col) => {
                  const value = col.resolver
                    ? col.resolver(meta[col.accessor])
                    : String(meta[col.accessor] || "");
                  return (
                    <th
                      key={`meta-${col.accessor}`}
                      align={col.align ?? "right"}
                    >
                      {value}
                    </th>
                  );
                })}
              </tr>
            </tfoot>
          )}
        </table>
      </div>
    </div>
  );
};
