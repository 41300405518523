import {
  GoogleAuthProvider,
  onAuthStateChanged,
  signInWithPopup,
  signOut,
  Auth,
} from "firebase/auth";
import { auth } from "../libraries/firebaseLibrary";

class authServiceClass {
  private auth: Auth = auth;

  /**
   * Sign in with Google.
   */
  public googleSignIn = async () => {
    try {
      const provider = new GoogleAuthProvider();
      const result = await signInWithPopup(this.auth, provider);

      return result.user;
    } catch (error: any) {
      console.error("Error during Google Sign-In:", error.message);
      throw error;
    }
  };

  /**
   * Sign out user.
   */
  public googleSignOut = async () => {
    try {
      await signOut(this.auth);
      console.log("User signed out successfully.");
    } catch (error: any) {
      console.error("Error during Google Sign-Out:", error.message);
      throw error;
    }
  };

  /**
   * Monitor authentication state.
   */
  public monitorAuthState = async () => {
    onAuthStateChanged(this.auth, (user) => {
      if (user) {
        console.log("User is signed in:", user);
      } else {
        console.log("No user is signed in.");
      }
    });
  };
}

export const authService = new authServiceClass();
