// src/redux/thunks/assetThunks.ts
import { AppDispatch, RootState } from "../store";
import { assetService } from "../../services/assetService";
import {
  fetchAssetsStart,
  fetchAssetsSuccess,
  fetchAssetsFailure,
  addAssetSuccess,
  updateAssetSuccess,
  deleteAssetSuccess,
} from "../slices/assetSlice";
import { IAsset } from "../../types";
import { setInvalidPin } from "../slices/userSlice";

export const fetchAssets =
  () => async (dispatch: AppDispatch, getState: () => RootState) => {
    const { uid, pin } = getState().user;
    dispatch(fetchAssetsStart());
    try {
      const assets = await assetService.fetchAssets({
        userId: uid,
        userPin: pin,
      });
      dispatch(fetchAssetsSuccess(assets));
    } catch (error: any) {
      if (error.message === "decryption") {
        dispatch(setInvalidPin());
        return;
      }
      dispatch(fetchAssetsFailure(error.message));
    }
  };

export const addAsset =
  (asset: IAsset) =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    const { uid, pin } = getState().user;
    try {
      const newAsset = await assetService.addAsset({
        userId: uid,
        userPin: pin,
        asset,
      });
      dispatch(addAssetSuccess(newAsset));
    } catch (error) {
      console.error("Error adding asset:", error);
    }
  };

export const updateAsset =
  (id: string, updatedAsset: IAsset) =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    const { uid, pin } = getState().user;

    try {
      const existingAsset = getState().assets.assets.find(
        (asset: any) => asset.id === id
      );

      if (!existingAsset) throw new Error("Asset not found");

      const completeUpdatedAsset = { ...existingAsset, ...updatedAsset };

      await assetService.updateAsset({
        userId: uid,
        userPin: pin,
        id,
        updatedAsset: completeUpdatedAsset,
      });

      dispatch(updateAssetSuccess({ id, ...completeUpdatedAsset }));
    } catch (error) {
      console.error("Error updating asset:", error);
    }
  };

export const deleteAsset =
  (id: string) => async (dispatch: AppDispatch, getState: () => RootState) => {
    const userId = getState().user.uid;
    try {
      await assetService.deleteAsset(userId, id);
      dispatch(deleteAssetSuccess(id));
    } catch (error) {
      console.error("Error deleting asset:", error);
    }
  };
