import CryptoJS from "crypto-js";

/**
 * Encrypt data using a PIN.
 * @param data - The data to encrypt (can be stringified JSON).
 * @param pin - The encryption key or PIN.
 * @returns The encrypted string.
 */
export const encryptData = (data: any, pin: string): string => {
  try {
    const stringifiedData = JSON.stringify(data); // Convert data to a string
    const encrypted = CryptoJS.AES.encrypt(stringifiedData, pin).toString();
    return encrypted;
  } catch (error) {
    console.error("Error encrypting data:", error);
    throw error;
  }
};

/**
 * Decrypt data using a PIN.
 * @param encryptedData - The encrypted string.
 * @param pin - The encryption key or PIN.
 * @returns The decrypted data.
 */
export const decryptData = (encryptedData: string, pin: string): any => {
  try {
    const bytes = CryptoJS.AES.decrypt(encryptedData, pin);
    const decrypted = bytes.toString(CryptoJS.enc.Utf8);
    return JSON.parse(decrypted); // Convert string back to original data
  } catch (error) {
    console.error("Error decrypting data:", error);
    throw new Error("decryption");
  }
};
