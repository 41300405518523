import styled from "styled-components";

export const PromptContent = styled.div`
  font-family: "Poppins", sans-serif;
  text-align: center;
  color: ${(props) => props.theme.colors.textPrimary || "#333"};
  line-height: 1.6;
  padding: 20px;
  border-radius: ${(props) => props.theme.borderRadius.medium || "8px"};
  background-color: ${(props) =>
    props.theme.colors.backgroundSecondary || "#f9f9f9"};
  box-shadow: ${(props) =>
    props.theme.shadows.medium || "0 4px 10px rgba(0, 0, 0, 0.1)"};
`;

export const AssetDetails = styled.div`
  margin: 15px 0;
  background: ${(props) => props.theme.colors.white || "#fff"};
  border-radius: ${(props) => props.theme.borderRadius.medium || "8px"};
  padding: 15px;
  box-shadow: ${(props) =>
    props.theme.shadows.small || "0 2px 5px rgba(0, 0, 0, 0.1)"};
  text-align: left;
`;

export const AssetDetail = styled.p`
  margin: 8px 0;
  font-size: ${(props) => props.theme.typography.fontSize.small || "14px"};
  line-height: 1.4;
  color: ${(props) => props.theme.colors.textSecondary || "#555"};

  & span {
    font-weight: bold;
    color: ${(props) => props.theme.colors.textPrimary || "#333"};
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 15px;
  margin-top: 20px;
`;
