import { useDispatch, useSelector } from "react-redux";
import {
  FaArrowUp,
  FaArrowDown,
  FaEye,
  FaTrash,
  FaTimes,
} from "react-icons/fa";

import // removeFromPlanning,
"../../redux/slices/counterSlice";
import { plannerThunks } from "../../redux/thunks";
import { AppDispatch, RootState } from "../../redux/store";
import "./PlanningModalScreenStyles.css";

export const PlanningModalTemplate = ({
  labelModal,
  setLabelModal,
}: {
  labelModal: { id: string; code: string; current: string } | null;
  setLabelModal: React.Dispatch<
    React.SetStateAction<{ id: string; code: string; current: string } | null>
  >;
}) => {
  const { map } = useSelector((state: RootState) => state.data.stocks);

  const dispatch = useDispatch<AppDispatch>();

  const handleLabelChange = (
    code: string,
    plan: "buy" | "sell" | "monitor"
  ) => {
    if (labelModal?.id) {
      // Update existing label
      dispatch(
        plannerThunks.update(labelModal.id, { id: labelModal.id, code, plan })
      );
    } else {
      dispatch(plannerThunks.add({ code, plan }));
    }
    setLabelModal(null);
  };

  const handleRemoveLabel = (id: string) => {
    dispatch(plannerThunks.deleteDoc({ id }));
    setLabelModal(null);
  };

  if (!labelModal) return null;

  const labelConfig: {
    [key: string]: { color: string; icon: JSX.Element; label: string };
  } = {
    buy: { color: "#4caf50", icon: <FaArrowUp />, label: "Buy" },
    sell: { color: "#f44336", icon: <FaArrowDown />, label: "Sell" },
    monitor: { color: "#ff9800", icon: <FaEye />, label: "Monitor" },
  };

  return (
    <div className="modal-backdrop">
      <div className="modal">
        <header className="modal-header">
          <h3>Update Label for {map[labelModal.code].displayName}</h3>
          <button onClick={() => setLabelModal(null)} className="close-button">
            <FaTimes />
          </button>
        </header>
        <div className="button-container">
          {Object.entries(labelConfig).map(([key, config]) => (
            <button
              key={key}
              className={`action-button ${
                key === labelModal.current ? "selected" : ""
              }`}
              style={{ backgroundColor: config.color }}
              onClick={() =>
                handleLabelChange(
                  labelModal.code,
                  key as "buy" | "sell" | "monitor"
                )
              }
            >
              {config.icon}
              <span>{config.label}</span>
            </button>
          ))}
        </div>
        <button
          onClick={() => handleRemoveLabel(labelModal.id)}
          className="remove-button"
        >
          <FaTrash />
          <span>Remove from Planning</span>
        </button>
      </div>
    </div>
  );
};
