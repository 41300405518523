import { useState } from "react";
import styled from "styled-components";

import { Input } from "../../components/Input/Input";
import { Button } from "../../components/Button/Button";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../redux";
import { userActions } from "../../redux/actions";

export const PincodeModal = () => {
  const dispatch = useDispatch<AppDispatch>();

  const [pincode, setPincode] = useState("");
  const [error, setError] = useState("");

  const handleSave = () => {
    if (/^\d{4}$/.test(pincode)) {
      dispatch(userActions.setUserPincode({ pin: pincode }));
    } else {
      setError("Pincode must be a 4-digit number");
    }
  };

  return (
    <Form>
      <Input
        type="password"
        name="pincode"
        value={pincode}
        onChange={(name, value) => {
          setPincode(value);
          setError("");
        }}
        label="Pincode"
        errors={{ pincode: error }}
        maxLength={4}
      />
      <ButtonContainer>
        <Button onClick={handleSave}>Save</Button>
      </ButtonContainer>
    </Form>
  );
};

const Form = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 10px;
`;
