// src/redux/thunks/assetThunks.ts
import { AppDispatch, RootState } from "../store";
import { plannerService } from "../../services";
import {
  addToPlanning,
  populatePlanning,
  removeFromPlanning,
  updatePlanning,
} from "../slices/counterSlice";
import { setInvalidPin } from "../slices/userSlice";
import { IPlanner, IPlannerDocument } from "../../types/plannerTypes";

export const fetch =
  () => async (dispatch: AppDispatch, getState: () => RootState) => {
    const { uid, pin } = getState().user;
    try {
      const records = await plannerService.fetch({
        userId: uid,
        userPin: pin,
      });
      dispatch(populatePlanning({ planning: records }));
    } catch (error: any) {
      if (error.message === "decryption") {
        dispatch(setInvalidPin());
        return;
      }
    }
  };

export const add =
  (data: IPlanner) =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    const { uid, pin } = getState().user;
    try {
      const record = await plannerService.add({
        userId: uid,
        userPin: pin,
        data: {
          id: "",
          ...data,
        },
      });
      dispatch(addToPlanning(record));
    } catch (error) {
      console.error("Error adding asset:", error);
    }
  };

export const update =
  (id: string, updatedRecord: IPlannerDocument) =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    const { uid, pin } = getState().user;
    const { planning } = getState().data;

    try {
      const existingRecord = planning.find((asset: any) => asset.id === id);

      if (!existingRecord) throw new Error("Asset not found");

      const updatedData = { ...existingRecord, ...updatedRecord };

      await plannerService.update({
        userId: uid,
        userPin: pin,
        id,
        updatedData,
      });

      dispatch(updatePlanning({ id: id, data: updatedData }));
    } catch (error) {
      console.error("Error updating asset:", error);
    }
  };

export const deleteDoc =
  ({ id }: { id: string }) =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    const userId = getState().user.uid;
    try {
      await plannerService.delete(userId, id);
      dispatch(removeFromPlanning({ id }));
    } catch (error) {
      console.error("Error deleting asset:", error);
    }
  };
