import { IPlannerDocumentList } from "../types";

export const applyFilters = (
  data: any,
  filters: { [key: string]: string },
  labels?: {
    [code: string]: { code: string; plan: "buy" | "sell" | "monitor" };
  }
) => {
  const metaOutput: any = {
    displayName: "",
    holdingQuantity: 0,
    holdingProfitAndLoss: 0,
    holdingInvestedAmount: 0,
    holdingLiveAmount: 0,
  };
  const outputData = data.filter((item: any) => {
    return Object.entries(filters).every(([key, value]) => {
      if (!value) return true; // Skip filter if value is empty
      return item[key] === value; // Apply filter
    });
  });
  const newData = outputData.map((value: any, key: any) => {
    metaOutput.holdingQuantity = metaOutput.holdingQuantity + 1;
    metaOutput.holdingProfitAndLoss =
      metaOutput.holdingProfitAndLoss + value.holdingProfitAndLoss;
    metaOutput.holdingInvestedAmount =
      metaOutput.holdingInvestedAmount + value.holdingInvestedAmount;
    metaOutput.holdingLiveAmount =
      metaOutput.holdingLiveAmount + value.holdingLiveAmount;

    return {
      ...value,
      label: labels?.[value.symbolIsin] ? labels[value.symbolIsin].plan : "",
    };
  });
  return {
    data: newData,
    meta: { ...metaOutput },
  };
};

export const groupDataGeneration = ({
  Data,
  groupBy = "tagSector",
}: {
  Data: any[];
  groupBy: string;
}) => {
  const output: any = {};
  const metaOutput: any = {
    displayName: "",
    holdingQuantity: 0,
    holdingProfitAndLoss: 0,
    holdingInvestedAmount: 0,
    holdingLiveAmount: 0,
  };
  Data.forEach((value: any, key: any) => {
    const tagKey = value[groupBy];

    metaOutput.holdingQuantity = metaOutput.holdingQuantity + 1;
    metaOutput.holdingProfitAndLoss =
      metaOutput.holdingProfitAndLoss + value.holdingProfitAndLoss;
    metaOutput.holdingInvestedAmount =
      metaOutput.holdingInvestedAmount + value.holdingInvestedAmount;
    metaOutput.holdingLiveAmount =
      metaOutput.holdingLiveAmount + value.holdingLiveAmount;

    if (!output[tagKey]) {
      output[tagKey] = {
        displayName: tagKey,
        holdingQuantity: 1,
        holdingProfitAndLoss: value.holdingProfitAndLoss,
        holdingInvestedAmount: value.holdingInvestedAmount,
        holdingLiveAmount: value.holdingLiveAmount,
      };
    } else {
      output[tagKey] = {
        ...output[tagKey],
        holdingQuantity: output[tagKey].holdingQuantity + 1,
        holdingProfitAndLoss:
          output[tagKey].holdingProfitAndLoss + value.holdingProfitAndLoss,
        holdingInvestedAmount:
          output[tagKey].holdingInvestedAmount + value.holdingInvestedAmount,
        holdingLiveAmount:
          output[tagKey].holdingLiveAmount + value.holdingLiveAmount,
      };
    }
  });
  return {
    data: Object.values(output),
    meta: { ...metaOutput },
  };
};

export const getUniqueOptions = (data: any[], key: string) => {
  // Extract unique values
  const uniqueValues = Array.from(new Set(data.map((item) => item[key])))
    .filter((value) => value) // Filter out undefined or null values
    .sort((a, b) => a.localeCompare(b)); // Sort alphabetically

  return [
    { label: "All", value: "" }, // Include "All" as the default option
    ...uniqueValues.map((value) => ({
      label: value, // Use the value as label
      value, // Use the value directly
    })),
  ];
};

export const plannerLabelsObject = (planning: IPlannerDocumentList) => {
  return Object.entries(planning).reduce((acc, [idx, { id, code, plan }]) => {
    acc[code] = { id, code, plan };
    return acc;
  }, {} as { [code: string]: { id: string; code: string; plan: "buy" | "sell" | "monitor" } });
};

// Generate the listWithObjects constant
export const stocksWithKeys = (data: any[]) => {
  return data.reduce((output: any, value: any) => {
    output[value.symbolIsin] = value;
    return output;
  }, {});
};
