import { Button } from "../../components/Button/Button";
import { AssetObjectConstants } from "../../constants";
import { IAssetDocument, IAssetList } from "../../types";
import {
  AssetDetail,
  AssetDetails,
  ButtonContainer,
  PromptContent,
} from "./AssetDeletePromptTemplateStyles";

export const AssetDeletePromptTemplate = ({
  deleteModal,
  closeDeleteModal,
  handleDelete,
  assets,
}: {
  deleteModal: {
    visible: boolean;
    asset?: IAssetDocument;
  };
  closeDeleteModal: () => void;
  handleDelete: () => void;
  assets: IAssetList;
}) => {
  return (
    <PromptContent>
      <h3>Are you sure you want to delete this asset?</h3>
      <AssetDetails>
        <AssetDetail>
          <span>Name:</span> {deleteModal?.asset?.name}
        </AssetDetail>
        <AssetDetail>
          <span>Type:</span>{" "}
          {deleteModal?.asset?.assetType
            ? AssetObjectConstants[deleteModal.asset.assetType].displayName
            : "N/A"}
        </AssetDetail>
        <AssetDetail>
          <span>Quantity:</span> {deleteModal?.asset?.quantity || "N/A"}
        </AssetDetail>
        <AssetDetail>
          <span>Purchase Value:</span> ₹
          {deleteModal?.asset?.purchaseValue?.toLocaleString() || "N/A"}
        </AssetDetail>
        <AssetDetail>
          <span>Current Value:</span> ₹
          {deleteModal?.asset?.currentValue?.toLocaleString() || "N/A"}
        </AssetDetail>
        {deleteModal?.asset?.purchaseDate && (
          <AssetDetail>
            <span>Purchase Date:</span>{" "}
            {new Date(deleteModal?.asset?.purchaseDate).toLocaleDateString()}
          </AssetDetail>
        )}
      </AssetDetails>
      <ButtonContainer>
        <Button variant="outline" size="medium" onClick={closeDeleteModal} flex>
          Cancel
        </Button>
        <Button variant="danger" size="medium" onClick={handleDelete} flex>
          Delete
        </Button>
      </ButtonContainer>
    </PromptContent>
  );
};
