import { Routes, Route } from "react-router-dom";
import { HomeScreen } from "./screens/HomeScreen/HomeScreen";
import { GroupScreen } from "./screens/GroupScreen/GroupScreen";
import { PlanningScreen } from "./screens/PlanningScreen/PlanningScreen";
import { AppDispatch, RootState } from "./redux/store";
import { useDispatch, useSelector } from "react-redux";
import { LoginScreen } from "./screens";
import { Layout } from "./components";
import { AssetListScreen } from "./screens/AssetListScreen/AssetListScreen";
import { useEffect } from "react";
import { appService } from "./services/appService";
import { PincodeModal } from "./screens/PincodeModalScreen/PincodeModalScreen";
import { Modal } from "./components/Modal/Modal";

const App = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { uid, pin, pinError } = useSelector((state: RootState) => state.user);

  useEffect(() => {
    if (!uid || !pin || pinError) return;
    appService.init(dispatch);
  }, [uid, pin, pinError, dispatch]);

  const AuthRoutes = (
    <Routes>
      <Route path="/" element={<HomeScreen />} />
      <Route path="/assets" element={<AssetListScreen />} />
      <Route path="/groups" element={<GroupScreen />} />
      <Route path="/planning" element={<PlanningScreen />} />
    </Routes>
  );

  const NonAuthRoutes = (
    <Routes>
      <Route path="*" element={<LoginScreen />} />
    </Routes>
  );

  return (
    <>
      {!uid ? NonAuthRoutes : <Layout activityArea={AuthRoutes} />}
      <Modal
        isOpen={!!uid && (!pin || pinError)}
        title={
          pinError ? "We are unable to decrypt your data" : "Enter Pincode"
        }
      >
        <PincodeModal />
      </Modal>
    </>
  );
};

export default App;
