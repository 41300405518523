import {
  FaBuilding,
  FaCoins,
  FaPiggyBank,
  FaLandmark,
  FaCar,
  FaUniversity,
  FaShoppingBasket,
  FaBitcoin,
  FaQuestion,
} from "react-icons/fa";

export const AssetTypesConstants: {
  value: string;
  displayName: string;
  icon: any; // All icons will follow this type
}[] = [
  { value: "mutual fund", displayName: "Mutual Fund", icon: FaPiggyBank },
  { value: "stock", displayName: "Stock", icon: FaUniversity },
  { value: "nps", displayName: "NPS", icon: FaLandmark },
  { value: "epf", displayName: "EPF", icon: FaCoins },
  { value: "real estate", displayName: "Real Estate", icon: FaBuilding },
  { value: "gold", displayName: "Gold", icon: FaShoppingBasket },
  { value: "crypto", displayName: "Cryptocurrency", icon: FaBitcoin },
  { value: "vehicle", displayName: "Vehicle", icon: FaCar },
  { value: "other", displayName: "Other", icon: FaQuestion },
];

export const AssetObjectConstants = AssetTypesConstants.reduce(
  (
    output: Record<
      string,
      { value: string; displayName: string; icon: typeof FaPiggyBank }
    >,
    value
  ) => {
    output[value.value] = value;
    return output;
  },
  {}
);

export const assetRowConstant = {
  id: "",
  name: "",
  assetType: "",
  quantity: 0,
  purchaseValue: 0,
  currentValue: 0,
};
