import { FaPlus, FaChevronUp, FaChevronDown } from "react-icons/fa";
import { Button } from "../../components/Button/Button";
import { Table } from "../../components/Table/Table";
import { AssetObjectConstants } from "../../constants";
import { formatToINR } from "../../utils/currencyUtils";
import {
  AccordionIcon,
  AssetListHeader,
  GroupContainer,
  GroupContent,
  GroupHeader,
  HeaderContent,
  IconContainer,
  LeftSection,
  RightSection,
  Summary,
  SummaryItem,
} from "./AssetListTemplateStyles";

export const AssetListTemplate = ({
  openModal,
  totalSummary,
  groupedAssets,
  toggleGroup,
  openGroup,
  columns,
  sortBy,
  sortOrder,
  handleSorting,
}: {
  openModal: () => void;
  totalSummary: {
    totalPurchaseValue: number;
    totalCurrentValue: number;
    totalProfit: number;
  };
  groupedAssets: any[];
  toggleGroup: (type: string) => void;
  openGroup: string | null;
  columns: any;
  sortBy: string | null;
  sortOrder: "asc" | "desc";
  handleSorting: (sortBy: string, sortOrder: "asc" | "desc") => void;
}) => {
  return (
    <div>
      {/* Total Summary Section */}
      <div className="total-summary">
        <AssetListHeader>
          <h1>Portfolio Summary</h1>
          <Button size="small" onClick={() => openModal()}>
            <FaPlus /> Add New Asset
          </Button>
        </AssetListHeader>

        <Summary>
          <SummaryItem>
            <span>Purchase Value:</span>
            <strong>
              {formatToINR(totalSummary.totalPurchaseValue, true)}
            </strong>
          </SummaryItem>
          <SummaryItem>
            <span>Current Value:</span>
            <strong>{formatToINR(totalSummary.totalCurrentValue, true)}</strong>
          </SummaryItem>
          <SummaryItem>
            <span>Profit/Loss:</span>
            <strong
              style={{
                color: totalSummary.totalProfit >= 0 ? "green" : "red",
              }}
            >
              {formatToINR(totalSummary.totalProfit, true)}
            </strong>
          </SummaryItem>
        </Summary>
      </div>

      {/* Asset List Header */}

      {/* Grouped Assets */}
      {Object.entries(groupedAssets).map(([type, groupAssets]) => {
        const totalCurrentValue = groupAssets.reduce(
          (sum: any, asset: any) => sum + Number(asset.currentValue),
          0
        );
        const Icon = AssetObjectConstants[type]?.icon;
        return (
          <GroupContainer key={type}>
            <GroupHeader onClick={() => toggleGroup(type)}>
              <HeaderContent>
                <LeftSection>
                  {/* Dynamically render the icon */}
                  <IconContainer>
                    <Icon />
                    <h3 style={{ marginLeft: "10px" }}>
                      {AssetObjectConstants[type]?.displayName || type}
                    </h3>
                  </IconContainer>
                </LeftSection>
                <RightSection>
                  <strong>{formatToINR(totalCurrentValue, true)}</strong>
                </RightSection>
                <AccordionIcon>
                  {openGroup === type ? <FaChevronUp /> : <FaChevronDown />}
                </AccordionIcon>
              </HeaderContent>
            </GroupHeader>

            {openGroup === type && (
              <GroupContent>
                <Table
                  data={groupAssets}
                  columns={columns}
                  sortBy={sortBy}
                  sortOrder={sortOrder}
                  setSorting={handleSorting}
                  emptyPlaceholder="No assets available."
                />
              </GroupContent>
            )}
          </GroupContainer>
        );
      })}
    </div>
  );
};
