import { useState, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  FaFilter,
  FaEye,
  FaArrowUp,
  FaArrowDown,
  FaPlus,
} from "react-icons/fa"; // Icons

import { RootState, AppDispatch } from "../../redux/store";
import {
  setFilter,
  resetFilter,
  setSorting,
} from "../../redux/slices/counterSlice";
import { applyFilters, getUniqueOptions } from "../../utils/dataUtils";
import { Select } from "../../components/Select/Select";
import { InvestmentTableTemplate } from "../../templates/InvestmentTable/InvestmentTable";
import { PlanningModalTemplate } from "../PlanningModalScreen/PlanningModalScreen";

import "./HomeScreenStyles.css";

export const HomeScreen = () => {
  const { filters } = useSelector((state: RootState) => state.data);
  const { sortBy, sortOrder } = useSelector(
    (state: RootState) => state.data.home
  );
  const { planningObject, stocks } = useSelector(
    (state: RootState) => state.data
  );
  const dispatch = useDispatch<AppDispatch>();
  const [showFilters, setShowFilters] = useState(false);

  const [labelModal, setLabelModal] = useState<{
    id: string;
    code: string;
    current: string;
  } | null>(null);

  const data = useMemo(
    () => applyFilters(stocks.list, filters, planningObject),
    [filters, planningObject, stocks.list]
  );

  const handleFilterChange = (key: string, value: string) => {
    dispatch(setFilter({ key, value }));
  };

  const filterOptions = {
    tagSector: getUniqueOptions(stocks.list, "tagSector"),
    tagMarketCap: getUniqueOptions(stocks.list, "tagMarketCap"),
    tagInvestmentHorizon: getUniqueOptions(stocks.list, "tagInvestmentHorizon"),
    tagGrowthPotential: getUniqueOptions(stocks.list, "tagGrowthPotential"),
  };

  const handleSorting = (sortBy: string, sortOrder: "asc" | "desc") => {
    dispatch(setSorting({ category: "home", sortBy, sortOrder }));
  };

  const columnOverrides = {
    displayName: {
      Header: "Name",
      accessor: "displayName",
      disableSortBy: true,
      bodyResolver: (value: string, row: any) => {
        const label = row.label || null;

        // Icon, color, and tooltip mapping for labels
        const labelConfig: {
          [key: string]: { icon: JSX.Element; color: string; tooltip: string };
        } = {
          buy: { icon: <FaArrowUp />, color: "#4caf50", tooltip: "Buy" }, // Green
          sell: { icon: <FaArrowDown />, color: "#f44336", tooltip: "Sell" }, // Red
          monitor: { icon: <FaEye />, color: "#ff9800", tooltip: "Monitor" }, // Orange
          default: { icon: <FaPlus />, color: "#bbb", tooltip: "Add Label" }, // Subtle gray for "Add Label"
        };

        const { icon, color, tooltip } =
          labelConfig[label] || labelConfig.default;

        return (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              gap: "10px",
              padding: "4px 0",
            }}
          >
            <span style={{ fontWeight: "bold", fontSize: "14px" }}>
              {value}
            </span>
            <button
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "24px",
                height: "24px",
                backgroundColor: "transparent",
                border: `1px solid ${color}`,
                borderRadius: "50%",
                color,
                fontSize: "12px",
                cursor: "pointer",
                transition: "transform 0.2s ease",
              }}
              onClick={() =>
                setLabelModal({
                  id: row.id,
                  code: row.symbolIsin,
                  current: label || "",
                })
              }
              title={tooltip}
            >
              {icon}
            </button>
          </div>
        );
      },
    },
  };

  return (
    <div className="home-container">
      <div className="home-header">
        <h1>Home</h1>
        <div
          className="filter-icon-wrapper"
          onClick={() => setShowFilters(!showFilters)}
        >
          <FaFilter />
          {Object.values(filters).some((value) => value) && (
            <span className="filter-indicator" data-tooltip="Filters applied">
              •
            </span>
          )}
        </div>
      </div>

      {showFilters && (
        <div className="filter-popup">
          <div className="filter-popup-header">
            <h3>Filters</h3>
            <button
              className="reset-button"
              onClick={() => dispatch(resetFilter())}
            >
              Reset All
            </button>
          </div>
          <div className="filter-options">
            {Object.keys(filterOptions).map((key) => (
              <Select
                key={key}
                name={key}
                label={key.replace("tag", "")}
                value={filters[key as keyof typeof filters] || ""}
                options={filterOptions[key as keyof typeof filterOptions]}
                onChange={handleFilterChange}
              />
            ))}
          </div>
        </div>
      )}

      <InvestmentTableTemplate
        data={data.data}
        meta={data.meta}
        sortBy={sortBy}
        sortOrder={sortOrder}
        setSorting={handleSorting}
        columnOverrides={columnOverrides}
      />

      <PlanningModalTemplate
        labelModal={labelModal}
        setLabelModal={setLabelModal}
      />
    </div>
  );
};
