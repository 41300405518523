import styled from "styled-components";

export const LayoutContainer = styled.div`
  display: flex;
  height: 100vh; /* Full viewport height */
  background-color: ${(props) => props.theme.colors.background};

  @media (max-width: 768px) {
    flex-direction: column; /* Stack NavSection and MainSection vertically */
  }
`;

export const NavSection = styled.div`
  background-color: ${(props) => props.theme.colors.secondary};
  width: 60px; /* Minimal width */
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: ${(props) => props.theme.spacing.medium} 0;
  box-shadow: ${(props) => props.theme.shadows.medium};
  position: sticky;
  top: 0;
  z-index: 100;

  @media (max-width: 768px) {
    flex-direction: row; /* Change to horizontal navigation */
    width: 100%; /* Full width for mobile */
    height: 60px; /* Adjust height */
    justify-content: space-between; /* Spread out items horizontally */
    padding: 0 ${(props) => props.theme.spacing.small};
  }
`;

export const NavHeader = styled.h2`
  font-size: ${(props) => props.theme.typography.fontSize.large};
  margin-bottom: ${(props) => props.theme.spacing.large};
  color: ${(props) => props.theme.colors.primary};
  text-transform: uppercase;

  @media (max-width: 768px) {
    display: none; /* Hide branding text on mobile */
  }
`;

export const NavList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  width: 100%; /* Ensure icons align properly */
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: 768px) {
    flex-direction: row; /* Horizontal navigation on mobile */
    justify-content: center;
  }
`;

export const NavItem = styled.li`
  margin-bottom: ${(props) => props.theme.spacing.medium};
  position: relative;

  @media (max-width: 768px) {
    margin-bottom: 0;
    margin-right: ${(props) => props.theme.spacing.small};
  }
`;

export const NavLink = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  background-color: transparent;
  color: ${(props) => props.theme.colors.white};
  border-radius: ${(props) => props.theme.borderRadius.medium};
  text-decoration: none;
  font-size: ${(props) => props.theme.typography.fontSize.large};
  transition: all 0.3s ease;

  &:hover {
    background-color: rgba(255, 255, 255, 0.1); /* Subtle hover effect */
  }

  &.active {
    background-color: ${(props) => props.theme.colors.primary};
    color: ${(props) => props.theme.colors.white};
  }

  @media (max-width: 768px) {
    width: 50px;
    height: 50px;
    font-size: ${(props) => props.theme.typography.fontSize.medium};
  }
`;

export const MainSection = styled.div`
  flex: 1;
  padding: ${(props) => props.theme.spacing.large};
  overflow: auto;
  background-color: ${(props) => props.theme.colors.white};
  border-radius: ${(props) => props.theme.borderRadius.large};
  box-shadow: ${(props) => props.theme.shadows.medium};

  @media (max-width: 768px) {
    padding: ${(props) => props.theme.spacing.small};
    border-radius: 0;
    box-shadow: none;
  }
`;

export const LogoutButton = styled.button`
  margin-top: auto; /* Push logout button to the bottom */
  background-color: transparent;
  color: ${(props) => props.theme.colors.white};
  border: none;
  border-radius: ${(props) => props.theme.borderRadius.medium};
  width: 40px;
  height: 40px;
  font-size: ${(props) => props.theme.typography.fontSize.medium};
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.3s ease;

  &:hover {
    background-color: rgba(255, 255, 255, 0.1); /* Subtle hover effect */
    transform: scale(1.1); /* Slight scaling on hover */
  }

  &:active {
    transform: scale(0.95); /* Shrinks slightly on click */
  }

  @media (max-width: 768px) {
    margin-top: 0; /* Align with navigation items */
  }
`;
