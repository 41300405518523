import React, { useState } from "react";
import { InputContainer, Label, InputField, ErrorMessage } from "./InputStyles";

interface InputProps
  extends Omit<React.InputHTMLAttributes<HTMLInputElement>, "onChange"> {
  name: string;
  value: string;
  onChange: (name: string, value: any) => void;
  label?: string; // Optional label for the input
  type?: string; // Input type (e.g., text, number, password)
  errors?: { [key: string]: string };
}

export const Input: React.FC<InputProps> = ({
  name,
  value,
  onChange,
  label,
  type = "text",
  errors,
  ...props
}) => {
  const [isFocused, setIsFocused] = useState(false);

  const handleFocus = () => setIsFocused(true);
  const handleBlur = () => setIsFocused(false);
  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = event.target;
    onChange(name, value);
  };

  return (
    <InputContainer>
      {label && (
        <Label className={isFocused || value ? "focused" : ""}>{label}</Label>
      )}
      <InputField
        {...props}
        name={name}
        type={type}
        value={value}
        onChange={handleChange}
        onFocus={handleFocus}
        onBlur={handleBlur}
      />
      {errors?.[name] && <ErrorMessage>{errors[name]}</ErrorMessage>}
    </InputContainer>
  );
};
