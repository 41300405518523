import { FirestoreService } from "./firestoreService";
import { IPlannerDocument } from "../types/plannerTypes";

class PlannerService extends FirestoreService<IPlannerDocument> {
  constructor() {
    super((userId) => `users/${userId}/planner`);
  }
}

export const plannerService = new PlannerService();
