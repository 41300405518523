import React, { useState, useRef } from "react";
import {
  SelectContainer,
  SelectLabel,
  SelectDisplay,
  SelectPlaceholder,
  DropdownArrow,
  SelectDropdown,
  SelectOption,
  ErrorMessage,
} from "./SelectStyles";

interface SelectProps {
  name: string;
  value: string;
  options: { label: string; value: string }[];
  onChange: (name: string, value: any) => void;
  label?: string; // Optional label for the select
  errors?: { [key: string]: string };
}

export const Select: React.FC<SelectProps> = ({
  name,
  value,
  options,
  onChange,
  label,
  errors,
}) => {
  const [isFocused, setIsFocused] = useState(false);
  const selectRef = useRef<HTMLDivElement>(null);

  const toggleDropdown = () => {
    setIsFocused((prev) => !prev);
  };

  const handleOptionClick = (optionValue: string) => {
    onChange(name, optionValue);
    setIsFocused(false); // Close the dropdown after selecting an option
  };

  const handleOutsideClick = (event: MouseEvent) => {
    if (
      selectRef.current &&
      !selectRef.current.contains(event.target as Node)
    ) {
      setIsFocused(false);
    }
  };

  React.useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  return (
    <SelectContainer ref={selectRef} isFocused={isFocused} hasValue={!!value}>
      {label && (
        <SelectLabel isFocused={isFocused || !!value}>{label}</SelectLabel>
      )}
      <SelectDisplay onClick={toggleDropdown}>
        <SelectPlaceholder hasValue={!!value}>
          {value
            ? options.find((option) => option.value === value)?.label
            : "Select..."}
        </SelectPlaceholder>
        <DropdownArrow>{isFocused ? "▲" : "▼"}</DropdownArrow>
      </SelectDisplay>
      {isFocused && (
        <SelectDropdown>
          {options.map((option) => (
            <SelectOption
              key={option.value}
              isSelected={option.value === value}
              onClick={() => handleOptionClick(option.value)}
            >
              {option.label}
            </SelectOption>
          ))}
        </SelectDropdown>
      )}
      {errors?.[name] && <ErrorMessage>{errors[name]}</ErrorMessage>}
    </SelectContainer>
  );
};
