import { useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { PlanningModalTemplate } from "../PlanningModalScreen/PlanningModalScreen";
import "./PlanningScreenStyles.css";

export const PlanningScreen = () => {
  const { planning, stocks } = useSelector((state: RootState) => state.data);

  // Modal state
  const [labelModal, setLabelModal] = useState<{
    id: string;
    code: string;
    current: string;
  } | null>(null);

  const toBuy = planning.filter((item) => item.plan === "buy");
  const toSell = planning.filter((item) => item.plan === "sell");
  const toMonitor = planning.filter((item) => item.plan === "monitor");

  return (
    <div className="planning-container">
      <h1>Planning</h1>
      <div className="planning-grid">
        {/* Buy Column */}
        <div className="planning-column">
          <div className="planning-column-header">Buy ({toBuy.length})</div>
          {toBuy.length > 0 ? (
            toBuy.map((stock, index) => (
              <div className="planning-item" key={index}>
                <span className="planning-item-text">
                  {stocks.map[stock.code].displayName}
                </span>
                <button
                  className="planning-item-button"
                  onClick={() =>
                    setLabelModal({
                      id: stock?.id,
                      code: stock.code,
                      current: stock.plan,
                    })
                  }
                >
                  Update Label
                </button>
              </div>
            ))
          ) : (
            <div className="planning-item-text">No stocks to buy</div>
          )}
        </div>

        {/* Sell Column */}
        <div className="planning-column">
          <div className="planning-column-header">Sell ({toSell.length})</div>
          {toSell.length > 0 ? (
            toSell.map((stock, index) => (
              <div className="planning-item" key={index}>
                <span className="planning-item-text">
                  {stocks.map[stock.code].displayName}
                </span>
                <button
                  className="planning-item-button"
                  onClick={() =>
                    setLabelModal({
                      id: stock?.id,
                      code: stock.code,
                      current: stock.plan,
                    })
                  }
                >
                  Update Label
                </button>
              </div>
            ))
          ) : (
            <div className="planning-item-text">No stocks to sell</div>
          )}
        </div>

        {/* Monitor Column */}
        <div className="planning-column">
          <div className="planning-column-header">
            Monitor ({toMonitor.length})
          </div>
          {toMonitor.length > 0 ? (
            toMonitor.map((stock, index) => (
              <div className="planning-item" key={index}>
                <span className="planning-item-text">
                  {stocks.map[stock.code].displayName}
                </span>
                <button
                  className="planning-item-button"
                  onClick={() =>
                    setLabelModal({
                      id: stock?.id,
                      code: stock.code,
                      current: stock.plan,
                    })
                  }
                >
                  Update Label
                </button>
              </div>
            ))
          ) : (
            <div className="planning-item-text">No stocks to monitor</div>
          )}
        </div>
      </div>

      {/* Modal for Updating Label */}
      <PlanningModalTemplate
        labelModal={labelModal}
        setLabelModal={setLabelModal}
      />
    </div>
  );
};
