import { useCallback, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FaEdit, FaTrash } from "react-icons/fa";

import { AppDispatch, RootState } from "../../redux/store";
import { formatToINR } from "../../utils/currencyUtils";
import { Button } from "../../components/Button/Button";
import { IAssetDocument } from "../../types";
import { setSorting } from "../../redux/slices/counterSlice";
import { ActionsContainer } from "./AssetListScreenStyles";
import { assetThunks } from "../../redux";
import { AssetListTemplate } from "../../templates/AssetListTemplate/AssetListTemplate";

import "./AssetListScreenStyles.ts";
import { Modal } from "../../components/Modal/Modal";
import { AssetDeletePromptTemplate } from "../../templates/AssetDeletePromptTemplate/AssetDeletePromptTemplate";
import { AssetFormScreen } from "../AssetFormScreen/AssetFormScreen";

export const AssetListScreen = () => {
  const dispatch = useDispatch<AppDispatch>();

  const { assets } = useSelector((state: RootState) => state.assets);
  const { sortBy, sortOrder } = useSelector(
    (state: RootState) => state.data.asset
  );

  const [showModal, setShowModal] = useState<{
    type: "add" | "edit" | any;
    visible: boolean;
    asset?: IAssetDocument;
  }>({ type: "add", visible: false });

  const [deleteModal, setDeleteModal] = useState<{
    visible: boolean;
    asset?: IAssetDocument;
  }>({ visible: false });

  const closeModal = useCallback(() => {
    setShowModal({ type: "add", visible: false });
  }, []);

  const openModal = useCallback((data?: IAssetDocument) => {
    setShowModal({
      type: data ? "edit" : "add",
      visible: true,
      asset: data,
    });
  }, []);

  const closeDeleteModal = () => {
    setDeleteModal({ visible: false });
  };

  const openDeleteModal = (visible: boolean, asset: IAssetDocument) => {
    setDeleteModal({ visible, asset });
  };

  const handleDelete = () => {
    if (deleteModal.asset) {
      dispatch(assetThunks.deleteAsset(deleteModal.asset.id));
    }
    closeDeleteModal();
  };

  const handleSorting = (sortBy: string, sortOrder: "asc" | "desc") => {
    dispatch(setSorting({ category: "asset", sortBy, sortOrder }));
  };

  // Calculate Total Summary
  const totalSummary = useMemo(() => {
    const totalPurchaseValue = assets.reduce(
      (sum, asset) => sum + Number(asset.purchaseValue),
      0
    );
    const totalCurrentValue = assets.reduce(
      (sum, asset) => sum + Number(asset.currentValue),
      0
    );
    const totalProfit = totalCurrentValue - totalPurchaseValue;
    console.log(totalProfit);

    return {
      totalPurchaseValue,
      totalCurrentValue,
      totalProfit,
    };
  }, [assets]);

  // Group assets by `assetType`
  const groupedAssets = useMemo(() => {
    return assets.reduce((acc, asset) => {
      const type = asset.assetType;
      if (!acc[type]) acc[type] = [];
      acc[type].push(asset);
      return acc;
    }, {} as Record<string, typeof assets>);
  }, [assets]);

  const [openGroup, setOpenGroup] = useState<string | null>(null);

  const toggleGroup = (type: string) => {
    setOpenGroup((prev) => (prev === type ? null : type));
  };

  const columns = [
    { header: "Name", accessor: "name", sortable: true },
    {
      header: "Quantity",
      accessor: "quantity",
      align: "center",
      sortable: true,
    },
    {
      header: "Purchase Value",
      accessor: "purchaseValue",
      align: "center",
      bodyResolver: (value: number) => formatToINR(value),
      sortable: true,
    },
    {
      header: "Current Value",
      accessor: "currentValue",
      align: "center",
      bodyResolver: (value: number) => formatToINR(value),
      sortable: true,
    },
    {
      header: "Actions",
      accessor: "actions",
      align: "center",
      bodyResolver: (_: any, row: any) => (
        <ActionsContainer>
          <Button
            variant="icon"
            size="small"
            onClick={() => openModal(row)}
            aria-label="Edit Asset"
          >
            <FaEdit />
          </Button>
          <Button
            variant="icon"
            size="small"
            onClick={() => openDeleteModal(true, row)}
            aria-label="Delete Asset"
          >
            <FaTrash />
          </Button>
        </ActionsContainer>
      ),
      sortable: false,
    },
  ];

  return (
    <>
      <AssetListTemplate
        openModal={openModal}
        totalSummary={totalSummary}
        groupedAssets={groupedAssets as any}
        toggleGroup={toggleGroup}
        openGroup={openGroup}
        columns={columns}
        sortBy={sortBy}
        sortOrder={sortOrder}
        handleSorting={handleSorting}
      />
      <Modal
        isOpen={deleteModal.visible}
        onClose={closeDeleteModal}
        title={"Delete Asset"}
      >
        <AssetDeletePromptTemplate
          deleteModal={deleteModal}
          closeDeleteModal={closeDeleteModal}
          handleDelete={handleDelete}
          assets={assets}
        />
      </Modal>
      <Modal
        isOpen={showModal.visible}
        onClose={closeModal}
        title={showModal.type === "add" ? "Add New Asset" : "Edit Asset"}
      >
        <AssetFormScreen
          action={showModal.type}
          data={showModal.asset}
          onClose={closeModal}
          onSave={closeModal}
        />
      </Modal>
    </>
  );
};
