import styled from "styled-components";

// Styled Components
export const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
`;

export const Content = styled.div`
  background: ${(props: any) => props.theme.colors.white};
  border-radius: ${(props: any) => props.theme.borderRadius.large};
  width: 90%;
  max-width: 500px;
  padding: ${(props: any) => props.theme.spacing.large};
  box-shadow: ${(props: any) => props.theme.shadows.medium};
  display: flex;
  flex-direction: column;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${(props: any) => props.theme.spacing.medium};
  font-size: ${(props: any) => props.theme.typography.fontSize.large};
  color: ${(props: any) => props.theme.colors.text};
`;

export const Body = styled.div`
  flex: 1;
  margin-bottom: ${(props: any) => props.theme.spacing.medium};
`;

export const CloseIconButton = styled.button`
  background: none;
  border: none;
  font-size: 1.5rem;
  color: ${(props: any) => props.theme.colors.text};
  cursor: pointer;
  padding: 0;

  &:hover {
    color: ${(props: any) => props.theme.colors.danger};
  }
`;
